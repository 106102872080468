import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import i18n from "./i18n";
import { I18nextProvider } from "react-i18next";
import "bootstrap/dist/css/bootstrap.min.css";
import "font-awesome/css/font-awesome.min.css";
import { getConfig } from "./config/auth0Config";
import { UserProvider } from "./components/Auth/UserProvider";
import history from "./utils/history";
import { Auth0Provider } from "@auth0/auth0-react";

//Custom styling
import "./styles/App.scss";

const onRedirectCallback = (appState) => {
    history.push(
        appState && appState.returnTo ? appState.returnTo : window.location.pathname
    );
};

const config = getConfig();

const providerConfig = {
    domain: config.domain,
    clientId: config.clientId,
    onRedirectCallback,
    authorizationParams: {
        redirect_uri: window.location.origin,
        audience: config.audience,
    },
    useRefreshTokens: false,
    cacheLocation: "localstorage",
};

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <I18nextProvider i18n={i18n}>
        <Auth0Provider {...providerConfig}>
            <UserProvider>
                <App />
            </UserProvider>
        </Auth0Provider>
    </I18nextProvider>
);