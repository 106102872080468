import React, { useEffect, useState } from "react";
import { Modal, Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { getActiveGeneralAssembly } from "./http/HttpClientHelper";
import GeneralAssemblyDocumentation from "./GeneralAssemblyDocumentation";

const GeneralAssembly = ({ show, handleClose }) => {
  const { t, i18n } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [generalAssembly, setGeneralAssembly] = useState(null);

  useEffect(() => {
    (async () => {
      setLoading(true);
      const activeGeneralAssembly = await getActiveGeneralAssembly();
      setGeneralAssembly(activeGeneralAssembly);
      setLoading(false);
    })();
  }, []);

  const getGeneralAssemblyName = () => {
    switch (i18n.language) {
      case "fr":
        return generalAssembly.name_Fr || t("generalAssembly.defaultTitle");
      case "nl":
        return generalAssembly.name || t("generalAssembly.defaultTitle");
      default:
        return t("generalAssembly.defaultTitle");
    }
  };

  return (
    <>
      {/* change loader with component loader from other branche when merged */}
      {loading && (
        <div className="loader-overlay">
          <div className="loader"></div>
        </div>
      )}
      {!loading && generalAssembly && (
        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>{getGeneralAssemblyName()}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <GeneralAssemblyDocumentation />
          </Modal.Body>
          <Modal.Footer className="justify-content-between">
            <Button
              variant="secondary"
              className="btn-eloket"
              onClick={handleClose}
            >
              {t("generalAssembly.not_possible.cancel")}
            </Button>
          </Modal.Footer>
        </Modal>
      )}
      ;
    </>
  );

  // return (
  //   <>
  //     {loading && (
  //       <div className="loader-overlay">
  //         <div className="loader"></div>
  //       </div>
  //     )}
  //     {generalAssembly == null || generalAssembly?.kid === "00000000-0000-0000-0000-000000000000" ? (
  //       <Modal show={show} onHide={handleClose}>
  //         <Modal.Header closeButton>
  //           <Modal.Title>{t('generalAssembly.defaultTitle')}</Modal.Title>
  //         </Modal.Header>
  //         <Modal.Body>
  //           <p>{t('generalAssembly.not_possible.message')}</p>
  //         </Modal.Body>
  //         <Modal.Footer className="justify-content-between">
  //           <Button
  //             variant="secondary"
  //             className="btn-eloket"
  //             onClick={handleClose}>
  //             {t('generalAssembly.not_possible.cancel')}
  //           </Button>
  //         </Modal.Footer>
  //       </Modal>
  //     ) : (
  //       <Modal show={show} onHide={handleClose}>
  //         <Modal.Header closeButton>
  //           <Modal.Title>{t('form_modal.title')}</Modal.Title>
  //         </Modal.Header>
  //         <Modal.Body>
  //         </Modal.Body>
  //         <Modal.Footer className="justify-content-between">
  //           <Button
  //             variant="secondary"
  //             className="btn-eloket"
  //             onClick={handleClose}>
  //             {t('form_modal.cancel')}
  //           </Button>
  //         </Modal.Footer>
  //       </Modal>
  //     )}
  //   </>
  // );
};

export default GeneralAssembly;
