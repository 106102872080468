import { apiConfig } from "./../config/apiConfig";

export default {
    nl: {
        eloket: {
            title: "E-LOKET",
            //subtitle: "Tijdelijk onbeschikbaar",
            subtitle: "Beheer van uw gegevens: privé, vennootschap(pen) en kanto(o)r(en).",
            link: "/eloket/myprofile",
            faqLink: "https://itaa-servicedesk.freshdesk.com/nl/support/solutions/folders/80000687962"
        },
        certificaat: {
            title: "CERTIFCAAT",
            subtitle: "",
            link: "/certificate",
        },
        itaaPortalUpdates: {
            title: "ITAA | Portaal: Updates",
            subtitle:
                "Raadpleeg hier de laatste aanpassingen aan het ITAA-portaal en bijhorende modules (e-loket, stagedagboek, permanente vorming, …).",
            link: process.env.REACT_APP_URL_PORTAL_UPDATES_NL,
        },
        generalAssembly: {
            title: "ALGEMENE VERGADERING",
            subtitle: "Documenten van de laatste algemene vergadering die heeft plaatsgevonden.",
        },
        forms: {
            title: "FORMULIEREN",
            subtitle: "Alle nuttige formulieren voor het beheer van uw lidmaatschap.",
            link: process.env.REACT_APP_URL_FORMS_NL,
            faqLink: "https://itaa-servicedesk.freshdesk.com/nl/support/solutions/folders/80000688082"
        },
        internshipBook: {
            title: "STAGEDAGBOEK",
            subtitle: "Beheer van de doelen en prestaties van de stagiair en evaluatie door de stagemeester.",
            link: process.env.REACT_APP_URL_STAGE,
            faqLink: "https://itaa-servicedesk.freshdesk.com/nl/support/solutions/folders/80000688083",
        },
        entranceExam: {
            title: "TOELATINGSEXAMEN",
            subtitle: "Uw toegang tot het reservatieportaal.",
            link: process.env.REACT_APP_URL_EXAM,
            faqLink: "https://itaa-servicedesk.freshdesk.com/nl/support/solutions/folders/80000688084",
        },
        intermediateExam: {
            title: "TUSSENTIJDSE PROEF",
            subtitle: "Uw toegang tot het reservatieportaal.",
            link: process.env.REACT_APP_URL_EXAM,
            faqLink: "https://itaa-servicedesk.freshdesk.com/nl/support/solutions/folders/80000688084",
        },
        intermediateExam2: {
            title: "TUSSENTIJDSE PROEF 2",
            subtitle: "Uw toegang tot het reservatieportaal.",
            link: process.env.REACT_APP_URL_EXAM,
            faqLink: "https://itaa-servicedesk.freshdesk.com/nl/support/solutions/folders/80000688084",
        },
        transferExam: {
            title: "OVERSTAP EXAMEN",
            subtitle: "Uw toegang tot het reservatieportaal.",
            link: process.env.REACT_APP_URL_EXAM,
            faqLink: "https://itaa-servicedesk.freshdesk.com/nl/support/solutions/folders/80000688084",
        },
        backpackExam: {
            title: "TUSSENTIJDSE PROEF (Overgangsmaatregel)",
            subtitle: "Uw toegang tot het reservatieportaal.",
            link: process.env.REACT_APP_URL_EXAM,
            faqLink: "https://itaa-servicedesk.freshdesk.com/nl/support/solutions/folders/80000688084",
        },
        finalExam: {
            title: "BEKWAAMHEIDSEXAMEN",
            subtitle: "Uw toegang tot het reservatieportaal.",
            link: process.env.REACT_APP_URL_EXAM,
            faqLink: "https://itaa-servicedesk.freshdesk.com/nl/support/solutions/folders/80000688084",
        },
        nonCertifiedExam: {
            title: "BEKWAAMHEIDSPROEF",
            subtitle: "Uw toegang tot het reservatieportaal.",
            link: process.env.REACT_APP_URL_EXAM,
            faqLink: "https://itaa-servicedesk.freshdesk.com/nl/support/solutions/folders/80000688084",
        },
        trainingProgram: {
            title: "PERMANENTE VORMING",
            subtitle: "Raadpleeg en beheer uw vormingsactiviteiten.",
            link: process.env.REACT_APP_URL_TRAINING,
            faqLink: "https://itaa-servicedesk.freshdesk.com/nl/support/solutions/folders/80000687961",
        },
        inisSystemFacturation: {
            title: "FACTUREN",
            subtitle: "Raadpleeg hier uw facturen en hun status.",
            faqLink: "https://itaa-servicedesk.freshdesk.com/nl/support/solutions/folders/80000699135",
        },
        inisSystemEducation: {
            title: "OPLEIDINGEN",
            subtitle: "Alle opleidingen van het ITAA.",
            faqLink: "https://itaa-servicedesk.freshdesk.com/nl/support/solutions/folders/80000688085",
        },
        professionalDocumentation: {
            title: "BEROEPSDOCUMENTATIE",
            subtitle: "Raadpleeg hier jouw vier ITAA-publicaties.",
            link: process.env.REACT_APP_URL_PROFESSIONAL_DOCUMENTATION_NL,
        },
        qualityOverview: {
            title: "KWALITEITSPORTAAL",
            subtitle: "Beheer via deze link uw kwaliteitstoetsingen.",
            link: "/quality",
            faqLink: "https://itaa-servicedesk.freshdesk.com/nl/support/solutions/folders/80000688086",
        },
        community: {
            title: "COMMUNITY",
            subtitle: "Forum voor de toetsers.",
            link: process.env.REACT_APP_URL_COMMUNITY,
        },
        estox: {
            title: "ESTOX",
            subtitle: "Elektronisch effectenregister.",
            faqLink: "https://itaa-servicedesk.freshdesk.com/nl/support/solutions/folders/80000460361",
        },
        mandateManagement: {
            title: "MANDATEN",
            subtitle: "Beheer uw dossiers via de mandatentoepassing. Hiervoor dient u aangemeld te zijn met eID of itsme.",
            faqLink: "https://itaa-servicedesk.freshdesk.com/nl/support/solutions/folders/80000705133",
        },
        app4Acc: {
            title: "APP4ACC",
            subtitle:
                "Digitale etalage van software voor accountants en belastingadviseurs.",
            link: process.env.REACT_APP_URL_APP4ACC_NL,
            faqLink: "https://itaa-servicedesk.freshdesk.com/nl/support/solutions/folders/80000718517",
        },
        companyWeb: {
            title: "COMPANYWEB",
            subtitle: "Jaarrekeningen en financiële informatie.",
            link: process.env.REACT_APP_URL_COMPANYWEB,
            faqLink: "https://itaa-servicedesk.freshdesk.com/nl/support/solutions/folders/80000688087",
        },
        excelForAccountancy: {
            title: "EXCEL FOR ACCOUNTANCY",
            subtitle: "Het potentieel van excel voor de boekhouding.",
            link: process.env.REACT_APP_URL_EXCELFORACCOUNTANCY_NL,
            faqLink: "https://itaa-servicedesk.freshdesk.com/nl/support/solutions/folders/80000688088",
        },
        serviceDesk: {
            title: "SERVICEDESK",
            subtitle: "Raadpleeg FAQ's en belangrijke meldingen. Stel uw vragen en volg ze op via tickets.",
            link: process.env.REACT_APP_URL_SERVICEDESK,
        },
        estoxAdmin: {
            title: "ESTOX - ADMIN",
            subtitle: "Beheer elektronisch effectenregister.",
        },
        crm: {
            title: "CRM",
            subtitle: "Rechtstreekse toegang tot het CRM van het ITAA.",
            link: process.env.REACT_APP_URL_LEGACY_CRM,
        },
        crm2: {
            title: "NIEUWE CRM",
            subtitle: "Rechtstreekse toegang tot de nieuwe CRM van het ITAA.",
            link: process.env.REACT_APP_URL_CRM,
        },
        certificateGeneration: {
            title: "CERTIFICAAT GENEREREN",
            subtitle: "Genereer een certificaat voor een ITAA lid.",
            link: "/certificate",
        },
        infoMessages: {
            title: "INFOBERICHT",
            subtitle: "Informatieberichten aan gebruikers instellen.",
            link: "/infomessage/overview",
        },
        moreinfo: "Meer info",
        generateCertificate: "Certificaat genereren",
        beExcellent:{
            title:"BEEXCELLENT",
            subtitle:"Modellen, tools en informatie om uw activiteiten te ondersteunen.",
            link:"https://beexcellent.itaa.be/Login/Saml/?redirect=https://beexcellent.itaa.be/",
        }
    },
    fr: {
        eloket: {
            title: "E-GUICHET",
            subtitle: "Gestion de vos données: privées, entreprise(s) et cabinet(s).",
            //subtitle: "Temporairement indisponible",
            link: "/eloket/myprofile",
            faqLink: "https://itaa-servicedesk.freshdesk.com/fr/support/solutions/folders/80000687962",
        },
        certificaat: {
            title: "CERTIFICAT",
            subtitle: "",
            link: apiConfig.UI_URL + "/certificate",
        },
        itaaPortalUpdates: {
            title: "ITAA | Portail: Mises à jour.",
            subtitle:
                "Consultez les dernières modifications apportées au portail ITAA et aux modules associés (dépôt électronique, carnet de stage, formation continue, etc.).",
            link: process.env.REACT_APP_URL_PORTAL_UPDATES_FR,
        },
        generalAssembly: {
            title: "ASSEMBLÉE GÉNÉRALE",
            subtitle: "Documents de la dernière assemblée générale qui a eu lieu.",
        },
        forms: {
            title: "FORMULAIRES",
            subtitle: "Tous les formulaires utiles pour la gestion de votre adhésion.",
            link: process.env.REACT_APP_URL_FORMS_NL,
            faqLink: "https://itaa-servicedesk.freshdesk.com/fr/support/solutions/folders/80000688082"
        },
        internshipBook: {
            title: "JOURNAL DE STAGE",
            subtitle: "Gestion des objectifs et des prestations du stagiaire et évaluation par le maître de stage.",
            link: process.env.REACT_APP_URL_STAGE,
            faqLink: "https://itaa-servicedesk.freshdesk.com/fr/support/solutions/folders/80000688083",
        },
        entranceExam: {
            title: "EXAMEN D'ADMISSION",
            subtitle: "Votre accès au portail de réservation.",
            link: process.env.REACT_APP_URL_EXAM,
            faqLink: "https://itaa-servicedesk.freshdesk.com/fr/support/solutions/folders/80000688084",
        },
        intermediateExam: {
            title: "EPREUVE INTERMEDIAIRE",
            subtitle: "Votre accès au portail de réservation.",
            link: process.env.REACT_APP_URL_EXAM,
            faqLink: "https://itaa-servicedesk.freshdesk.com/fr/support/solutions/folders/80000688084",
        },
        intermediateExam2: {
            title: "EPREUVE INTERMEDIAIRE 2",
            subtitle: "Votre accès au portail de réservation.",
            link: process.env.REACT_APP_URL_EXAM,
            faqLink: "https://itaa-servicedesk.freshdesk.com/fr/support/solutions/folders/80000688084",
        },
        transferExam: {
            title: "EXAMEN PASSERELLE",
            subtitle: "Votre accès au portail de réservation.",
            link: process.env.REACT_APP_URL_EXAM,
            faqLink: "https://itaa-servicedesk.freshdesk.com/fr/support/solutions/folders/80000688084",
        },
        backpackExam: {
            title: "EPREUVE INTERMEDIAIRE (Mesure transitoire)",
            subtitle: "Votre accès au portail de réservation.",
            link: process.env.REACT_APP_URL_EXAM,
            faqLink: "https://itaa-servicedesk.freshdesk.com/fr/support/solutions/folders/80000688084",
        },
        finalExam: {
            title: "EXAMEN D'APTITUDE",
            subtitle: "Votre accès au portail de réservation.",
            link: process.env.REACT_APP_URL_EXAM,
            faqLink: "https://itaa-servicedesk.freshdesk.com/fr/support/solutions/folders/80000688084",
        },
        nonCertifiedExam: {
            title: "EPREUVE D'APTITUDE",
            subtitle: "Votre accès au portail de réservation.",
            link: process.env.REACT_APP_URL_EXAM,
            faqLink: "https://itaa-servicedesk.freshdesk.com/fr/support/solutions/folders/80000688084",
        },
        trainingProgram: {
            title: "FORMATION CONTINUE",
            subtitle: "Consultez et gérez vos activités de formation.",
            link: process.env.REACT_APP_URL_TRAINING,
            faqLink: "https://itaa-servicedesk.freshdesk.com/fr/support/solutions/folders/80000687961",
        },
        inisSystemFacturation: {
            title: "FACTURES",
            subtitle: "Consultez ici vos factures et leur statut.",
            faqLink: "https://itaa-servicedesk.freshdesk.com/fr/support/solutions/folders/80000699135",
        },
        inisSystemEducation: {
            title: "FORMATIONS",
            subtitle: "Toutes les formations de l’ITAA.",
            faqLink: "https://itaa-servicedesk.freshdesk.com/fr/support/solutions/folders/80000688085",
        },
        professionalDocumentation: {
            title: "DOCUMENTATION PROFESSIONNELLE",
            subtitle: "Consultez ici vos quatre livres de l’ITAA.",
            link: process.env.REACT_APP_URL_PROFESSIONAL_DOCUMENTATION_FR,
        },
        qualityOverview: {
            title: "PLATEFORME QUALITÉ",
            subtitle: "Gérez via ce lien vos revues qualité.",
            link: "/quality",
            faqLink: "https://itaa-servicedesk.freshdesk.com/fr/support/solutions/folders/80000688086",
        },
        community: {
            title: "COMMUNITY",
            subtitle: "Forum pour les rapporteurs.",
            link: process.env.REACT_APP_URL_COMMUNITY,
        },
        estox: {
            title: "ESTOX",
            subtitle: "Registre électronique des titres.",
            faqLink: "https://itaa-servicedesk.freshdesk.com/fr/support/solutions/folders/80000460361",
        },
        mandateManagement: {
            title: "MANDATS",
            subtitle: "Gérez vos dossiers via l'application des mandats. Vous devez être connecté avec eID ou itsme.",
            faqLink: "https://itaa-servicedesk.freshdesk.com/nl/support/solutions/folders/80000705133",
        },
        app4Acc: {
            title: "APP4ACC",
            subtitle:
                "Vitrine numérique des logiciels pour les experts-comptables et conseillers fiscaux.",
            link: process.env.REACT_APP_URL_APP4ACC_FR,
            faqLink: "https://itaa-servicedesk.freshdesk.com/fr/support/solutions/folders/80000718517",
        },
        companyWeb: {
            title: "COMPANYWEB",
            subtitle: "Comptes annuels et informations financières.",
            link: process.env.REACT_APP_URL_COMPANYWEB,
            faqLink: "https://itaa-servicedesk.freshdesk.com/fr/support/solutions/folders/80000688087",
        },
        excelForAccountancy: {
            title: "EXCEL FOR ACCOUNTANCY",
            subtitle: "Tout le potentiel d’excel dans la pratique comptable.",
            link: process.env.REACT_APP_URL_EXCELFORACCOUNTANCY_FR,
            faqLink: "https://itaa-servicedesk.freshdesk.com/nl/support/solutions/folders/80000688088",
        },
        serviceDesk: {
            title: "SERVICEDESK",
            subtitle:
                "Consultez les FAQ et les annonces importantes. Posez vos questions et faites le suivi de vos tickets.",
            link: process.env.REACT_APP_URL_SERVICEDESK,
        },
        estoxAdmin: {
            title: "ESTOX - ADMIN",
            subtitle: "Administration du registre électronique des titres.",
        },
        crm: {
            title: "CRM",
            subtitle: "Accès direct au CRM de l’ITAA.",
            link: process.env.REACT_APP_URL_LEGACY_CRM,
        },
        crm2: {
            title: "NOUVEAU CRM",
            subtitle: "Accès direct au nouveau CRM de l’ITAA.",
            link: process.env.REACT_APP_URL_CRM,
        },
        certificateGeneration: {
            title: "GENERER CERTIFICAT",
            subtitle: "Générer un certificat pour un membre de l’ITAA.",
        },
        infoMessages: {
            title: "MESSAGE D'INFORMATION",
            subtitle: "Configurer les messages d'information aux utilisateurs.",
            link: "/infomessage/overview",
        },
        moreinfo: "Plus d'infos",
        generateCertificate: "Générer certificat.",
        beExcellent:{
            title:"BEEXCELLENT ",
            subtitle:"Modèles, outils et informations destinés à faciliter vos activités.",
            link:"https://beexcellent.itaa.be/Login/Saml/?redirect=https://beexcellent.itaa.be/",
        }
    },
};