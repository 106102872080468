export default {
  nl: {
    defaultTitle: "Algemene vergadering",
    not_possible: {
      message:
        "Inschrijven voor de algemene vergadering is op dit moment niet mogelijk.",
      cancel: "Sluiten",
    },
    documentation: {
      infoForAttendingAGM: "<a href='https://itaa.be/documents/av/ITAA%20AV%20-%20algemene%20informatie.pdf' target='_blank'>Bekijk hier de algemene informatie voor het bijwonen van de AV</a> | <a href='https://itaa.be/documents/ag/ITAA%20AG%20-%20informations%20generales.pdf' target='_blank'>FR</a>",
      agenda2024: "<a href='https://itaa.be/documents/av/ITAA%20AV%20-%20dagorde.pdf' target='_blank'>Bekijk hier de dagorde 2024</a> | <a href='https://itaa.be/documents/ag/ITAA%20AG%20-%20ordre%20du%20jour.pdf' target='_blank'>FR</a>",
      balance2023: "<a href='https://itaa.be/documents/av/ITAA%20AV%20-%20balans.pdf' target='_blank'>Bekijk hier de balans per 31.12.2023</a> | <a href='https://itaa.be/documents/ag/ITAA%20AG%20-%20bilan.pdf' target='_blank'>FR</a>",
      balance2023_2Years: "<a href='https://itaa.be/documents/av/ITAA%20AV%20-%20balans%202jaar.pdf' target='_blank'>Bekijk hier de balans per 31.12.2023 – 2 jaar</a> | <a href='https://www.itaa.be/documents/ag/ITAA%20AG%20-%20bilan%202ans.pdf' target='_blank'>FR</a>",
      incomeStatement2023: "<a href='https://itaa.be/documents/av/ITAA%20AV%20-%20resultatenrekening.pdf' target='_blank'>Bekijk hier de resultatenrekening per 31.12.2023</a> | <a href='https://itaa.be/documents/ag/ITAA%20AG%20-%20compte%20de%20resultats.pdf' target='_blank'>FR</a>",
      incomeStatement2023_2Years: "<a href='https://itaa.be/documents/av/ITAA%20AV%20-%20resultatenrekening%202jaar.pdf' target='_blank'>Bekijk hier de resultatenrekening per 31.12.2023 – 2 jaar</a> | <a href='https://www.itaa.be/documents/ag/ITAA%20AG%20-%20compte%20de%20resultats%202ans.pdf' target='_blank'>FR</a>",
      comparisonBudgetResults: "<a href='https://itaa.be/documents/av/ITAA%20AV%20-%20vergelijking%20begroting%20-%20resultatenrekening.pdf' target='_blank'>Bekijk hier de vergelijking begroting 2023 – resultatenrekening 2023</a> | <a href='https://itaa.be/documents/ag/ITAA%20AG%20-%20comparaison%20budget%20-%20compte%20de%20resultats.pdf' target='_blank'>FR</a>",
      budgetProposalNewYear: "<a href='https://itaa.be/documents/av/ITAA%20AV%20-%20voorstel%20van%20begroting%20voor%20het%20nieuwe%20boekjaar.pdf' target='_blank'>Bekijk hier het voorstel van begroting voor het nieuwe boekjaar</a> | <a href='https://itaa.be/documents/ag/ITAA%20AG%20-%20proposition%20de%20budget%20pour%20le%20nouvel%20exercice.pdf' target='_blank'>FR</a>",
      annualReport: "<a href='https://www.itaa.be/nl/jaarverslag/' target='_blank'>Bekijk hier het activiteitenverslag van het afgelopen jaar</a> | <a href='https://www.itaa.be/fr/rapportannuel/' target='_blank'>FR</a>",
      commissionersReport: "<a href='https://itaa.be/documents/av/ITAA%20AV%20-%20verslag%20van%20de%20commissarissen.pdf' target='_blank'>Bekijk hier het verslag van de commissarissen</a> | <a href='https://itaa.be/documents/ag/ITAA%20AG%20-%20rapport%20des%20commissaires.pdf' target='_blank'>FR</a>",
      proxyVoting: "<a href='https://itaa.be/documents/av/ITAA%20AV%20-%20volmacht%20verlenen.pdf' target='_blank'>Volmacht verlenen: hoe?</a> | <a href='https://itaa.be/documents/ag/ITAA%20AG%20-%20donner%20une%20procuration.pdf' target='_blank'>FR</a>",
      proxyLoginChat: "<a href='https://itaa.be/documents/av/ITAA%20AV%20-%20Proxy%20login%20-%20stemmen%20op%20afstand%20-%20chatfunctie.pdf' target='_blank'>Proxy login – Stemmen op afstand – Chat</a> | <a href='https://itaa.be/documents/av/ITAA%20AV%20-%20Proxy%20login%20-%20stemmen%20op%20afstand%20-%20chatfunctie.pdf' target='_blank'>FR</a>",
      votingFromHall: "<a href='https://itaa.be/documents/av/ITAA%20AV%20-%20Stemmen%20vanuit%20de%20zaal.pdf' target='_blank'>Stemmen vanuit de zaal</a> | <a href='https://itaa.be/documents/ag/ITAA%20AG%20-%20Voter%20en%20presentiel.pdf' target='_blank'>FR</a>"
    }
  },
  fr: {
    defaultTitle: "Assemblée générale",
    not_possible: {
      message:
        "L'inscription à l'assemblée générale n'est actuellement pas possible.",
      cancel: "Fermer",
    },
    documentation: {
      infoForAttendingAGM: "<a href='https://itaa.be/documents/ag/ITAA%20AG%20-%20informations%20generales.pdf' target='_blank'>Consulter ici les informations générales sur la participation à l'assemblée générale</a> | <a href='https://itaa.be/documents/av/ITAA%20AV%20-%20algemene%20informatie.pdf' target='_blank'>NL</a>",
      agenda2024: "<a href='https://itaa.be/documents/ag/ITAA%20AG%20-%20ordre%20du%20jour.pdf' target='_blank'>Consulter ici l’ordre du jour 2024</a> | <a href='https://itaa.be/documents/av/ITAA%20AV%20-%20dagorde.pdf' target='_blank'>NL</a>",
      balance2023: "<a href='https://itaa.be/documents/ag/ITAA%20AG%20-%20bilan.pdf' target='_blank'>Consulter ici le bilan au 31.12.2023</a> | <a href='https://itaa.be/documents/av/ITAA%20AV%20-%20balans.pdf' target='_blank'>NL</a>",
      balance2023_2Years: "<a href='https://www.itaa.be/documents/ag/ITAA%20AG%20-%20bilan%202ans.pdf' target='_blank'>Consulter ici le bilan au 31.12.2023 – 2 ans</a> | <a href='https://itaa.be/documents/av/ITAA%20AV%20-%20balans%202jaar.pdf' target='_blank'>NL</a>",
      incomeStatement2023: "<a href='https://itaa.be/documents/ag/ITAA%20AG%20-%20compte%20de%20resultats.pdf' target='_blank'>Consulter ici le compte de résultats au 31.12.2023</a> | <a href='https://itaa.be/documents/av/ITAA%20AV%20-%20resultatenrekening.pdf' target='_blank'>NL</a>",
      incomeStatement2023_2Years: "<a href='https://www.itaa.be/documents/ag/ITAA%20AG%20-%20compte%20de%20resultats%202ans.pdf' target='_blank'>Consulter ici le compte de résultats au 31.12.2023 – 2 ans</a> | <a href='https://itaa.be/documents/av/ITAA%20AV%20-%20resultatenrekening%202jaar.pdf' target='_blank'>NL</a>",
      comparisonBudgetResults: "<a href='https://itaa.be/documents/ag/ITAA%20AG%20-%20comparaison%20budget%20-%20compte%20de%20resultats.pdf' target='_blank'>Consulter ici la comparaison budget 2023 – compte de résultats 2023</a> | <a href='https://itaa.be/documents/av/ITAA%20AV%20-%20vergelijking%20begroting%20-%20resultatenrekening.pdf' target='_blank'>NL</a>",
      budgetProposalNewYear: "<a href='https://itaa.be/documents/ag/ITAA%20AG%20-%20proposition%20de%20budget%20pour%20le%20nouvel%20exercice.pdf' target='_blank'>Consulter ici la proposition de budget pour le nouvel exercice</a> | <a href='https://itaa.be/documents/av/ITAA%20AV%20-%20voorstel%20van%20begroting%20voor%20het%20nieuwe%20boekjaar.pdf' target='_blank'>NL</a>",
      annualReport: "<a href='https://www.itaa.be/fr/rapportannuel/' target='_blank'>Consulter ici le rapport d’activités de l’an dernier</a> | <a href='https://www.itaa.be/nl/jaarverslag/' target='_blank'>NL</a>",
      commissionersReport: "<a href='https://itaa.be/documents/ag/ITAA%20AG%20-%20rapport%20des%20commissaires.pdf' target='_blank'>Consulter ici le rapport des commissaires</a> | <a href='https://itaa.be/documents/av/ITAA%20AV%20-%20verslag%20van%20de%20commissarissen.pdf' target='_blank'>NL</a>",
      proxyVoting: "<a href='https://itaa.be/documents/ag/ITAA%20AG%20-%20donner%20une%20procuration.pdf' target='_blank'>Donner une procuration : comment?</a> | <a href='https://itaa.be/documents/av/ITAA%20AV%20-%20volmacht%20verlenen.pdf' target='_blank'>NL</a>",
      proxyLoginChat: "<a href='https://itaa.be/documents/av/ITAA%20AV%20-%20Proxy%20login%20-%20stemmen%20op%20afstand%20-%20chatfunctie.pdf' target='_blank'>Proxy login – Voter à distance – Chat</a> | <a href='https://itaa.be/documents/av/ITAA%20AV%20-%20Proxy%20login%20-%20stemmen%20op%20afstand%20-%20chatfunctie.pdf' target='_blank'>NL</a>",
      votingFromHall: "<a href='https://itaa.be/documents/ag/ITAA%20AG%20-%20Voter%20en%20presentiel.pdf' target='_blank'>Voter en presentiel</a> | <a href='https://itaa.be/documents/av/ITAA%20AV%20-%20Stemmen%20vanuit%20de%20zaal.pdf' target='_blank'>NL</a>"
    }
  },
};
