import axios from 'axios';
import { apiConfig } from './config/apiConfig';
import { handleApiError } from './errorHandler';
import { jwtDecode } from "jwt-decode";

let handleLogout = () => {

};

const axiosClient = axios.create({
  baseURL: apiConfig.BFF_URL,
  headers: {
    'Content-Type': 'application/json',
    'Cache-Control': 'no-cache'
  },
});


const isTokenValid = (token) => {
  if (!token) {
    return false;
  }

  const decodedToken = jwtDecode(token);
  const iatDate = new Date(decodedToken.iat * 1000);
  const currentDate = new Date();
  const diffInHours = (currentDate - iatDate) / (1000 * 60 * 60);
  console.log(diffInHours);
  return diffInHours >= 8 ? false : true;
};

axiosClient.interceptors.request.use(async (config) => {

  const token = localStorage.getItem('accessToken');

  if (token && isTokenValid(token)) {
    config.headers["Authorization"] = `Bearer ${token}`;
    return config;
  } else {
    handleLogout();
  }
});

axiosClient.interceptors.response.use(
  (response) => response,
  (error) => {
    const accessToken = localStorage.getItem('accessToken');

    if (accessToken && isTokenValid(accessToken)) {
      error.config.headers.Authorization = `Bearer ${accessToken}`;
    } else {
      handleLogout();
    }
    const showToast = !error.config.headers['X-Skip-Error-Toast'];
    handleApiError(error, showToast);
    return Promise.reject(error);
  }
);

export const setLogoutHandler = (logoutFunction) => {
  handleLogout = logoutFunction;
};

export default axiosClient;