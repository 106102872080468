import React from 'react';
import { useTranslation } from 'react-i18next';

const GeneralAssemblyDocumentation = () => {
  const { t } = useTranslation();

  return (
    <>
      <div dangerouslySetInnerHTML={{ __html: t('generalAssembly.documentation.infoForAttendingAGM') }} />
      <div dangerouslySetInnerHTML={{ __html: t('generalAssembly.documentation.agenda2024') }} />
      <div dangerouslySetInnerHTML={{ __html: t('generalAssembly.documentation.balance2023') }} />
      <div dangerouslySetInnerHTML={{ __html: t('generalAssembly.documentation.balance2023_2Years') }} />
      <div dangerouslySetInnerHTML={{ __html: t('generalAssembly.documentation.incomeStatement2023') }} />
      <div dangerouslySetInnerHTML={{ __html: t('generalAssembly.documentation.incomeStatement2023_2Years') }} />
      <div dangerouslySetInnerHTML={{ __html: t('generalAssembly.documentation.comparisonBudgetResults') }} />
      <div dangerouslySetInnerHTML={{ __html: t('generalAssembly.documentation.budgetProposalNewYear') }} />
      <div dangerouslySetInnerHTML={{ __html: t('generalAssembly.documentation.annualReport') }} />
      <div dangerouslySetInnerHTML={{ __html: t('generalAssembly.documentation.commissionersReport') }} />
      <div dangerouslySetInnerHTML={{ __html: t('generalAssembly.documentation.proxyVoting') }} />
      <div dangerouslySetInnerHTML={{ __html: t('generalAssembly.documentation.proxyLoginChat') }} />
      <div dangerouslySetInnerHTML={{ __html: t('generalAssembly.documentation.votingFromHall') }} />
    </>
  );
};

export default GeneralAssemblyDocumentation;
